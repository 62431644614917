// Color List
$uiGradients_list: (
	harmonic-energy : ( #16a085, #f4d03f ),
	playing-with-reds : ( #d31027, #ea384d ),
	sunny-days : ( #ede574, #e1f5c4 ),
	green-beach : ( #02aab0, #00cdac ),
	intuitive-purple : ( #da22ff, #9733ee ),
	emerald-water : ( #348f50, #56b4d3 ),
	lemon-twist : ( #3ca55c, #b5ac49 ),
	horizon : ( #003973, #e5e5be ),
	rose-water : ( #e55d87, #5fc3e4 ),
	frozen : ( #403b4a, #e7e9bb ),
	mango-pulp : ( #f09819, #edde5d ),
	bloody-mary : ( #ff512f, #dd2476 ),
	aubergine : ( #aa076b, #61045f ),
	aqua-marine : ( #1a2980, #26d0ce ),
	sunrise : ( #ff512f, #f09819 ),
	purple-paradise : ( #1d2b64, #f8cdda ),
	sea-weed : ( #4cb8c4, #3cd3ad ),
	pinky : ( #dd5e89, #f7bb97 ),
	cherry : ( #eb3349, #f45c43 ),
	mojito : ( #1d976c, #93f9b9 ),
	juicy-orange : ( #ff8008, #ffc837 ),
	mirage : ( #16222a, #3a6073 ),
	steel-gray : ( #1f1c2c, #928dab ),
	kashmir : ( #614385, #516395 ),
	electric-violet : ( #4776e6, #8e54e9 ),
	venice-blue : ( #085078, #85d8ce ),
	bora-bora : ( #2bc0e4, #eaecc6 ),
	moss : ( #134e5e, #71b280 ),
	shroom-haze : ( #5c258d, #4389a2 ),
	mystic : ( #757f9a, #d7dde8 ),
	midnight-city : ( #232526, #414345 ),
	sea-blizz : ( #1cd8d2, #93edc7 ),
	opa : ( #3d7eaa, #ffe47a ),
	titanium : ( #283048, #859398 ),
	mantle : ( #24c6dc, #514a9d ),
	dracula : ( #dc2424, #4a569d ),
	peach : ( #ed4264, #ffedbc ),
	moonrise : ( #dae2f8, #d6a4a4 ),
	clouds : ( #ece9e6, #ffffff ),
	stellar : ( #7474bf, #348ac7 ),
	bourbon : ( #ec6f66, #f3a183 ),
	calm-darya : ( #5f2c82, #49a09d ),
	influenza : ( #c04848, #480048 ),
	shrimpy : ( #e43a15, #e65245 ),
	army : ( #414d0b, #727a17 ),
	miaka : ( #fc354c, #0abfbc ),
	pinot-noir : ( #4b6cb7, #182848 ),
	day-tripper : ( #f857a6, #ff5858 ),
	namn : ( #a73737, #7a2828 ),
	blurry-beach : ( #d53369, #cbad6d ),
	vasily : ( #e9d362, #333333 ),
	a-lost-memory : ( #de6262, #ffb88c ),
	petrichor : ( #666600, #999966 ),
	jonquil : ( #ffeeee, #ddefbb ),
	sirius-tamed : ( #efefbb, #d4d3dd ),
	kyoto : ( #c21500, #ffc500 ),
	misty-meadow : ( #215f00, #e4e4d9 ),
	aqualicious : ( #50c9c3, #96deda ),
	moor : ( #616161, #9bc5c3 ),
	almost : ( #ddd6f3, #faaca8 ),
	forever-lost : ( #5d4157, #a8caba ),
	winter : ( #e6dada, #274046 ),
	autumn : ( #dad299, #b0dab9 ),
	candy : ( #d3959b, #bfe6ba ),
	reef : ( #00d2ff, #3a7bd5 ),
	the-strain : ( #870000, #190a05 ),
	dirty-fog : ( #b993d6, #8ca6db ),
	earthly : ( #649173, #dbd5a4 ),
	virgin : ( #c9ffbf, #ffafbd ),
	ash : ( #606c88, #3f4c6b ),
	shadow-night : ( #000000, #53346d ),
	cherryblossoms : ( #fbd3e9, #bb377d ),
	parklife : ( #add100, #7b920a ),
	dance-to-forget : ( #ff4e50, #f9d423 ),
	starfall : ( #f0c27b, #4b1248 ),
	red-mist : ( #000000, #e74c3c ),
	teal-love : ( #aaffa9, #11ffbd ),
	neon-life : ( #b3ffab, #12fff7 ),
	man-of-steel : ( #780206, #061161 ),
	amethyst : ( #9d50bb, #6e48aa ),
	cheer-up-emo-kid : ( #556270, #ff6b6b ),
	shore : ( #70e1f5, #ffd194 ),
	facebook-messenger : ( #00c6ff, #0072ff ),
	soundcloud : ( #fe8c00, #f83600 ),
	behongo : ( #52c234, #061700 ),
	servquick : ( #485563, #29323c ),
	friday : ( #83a4d4, #b6fbff ),
	martini : ( #fdfc47, #24fe41 ),
	metallic-toad : ( #abbaab, #ffffff ),
	between-the-clouds : ( #73c8a9, #373b44 ),
	crazy-orange-i : ( #d38312, #a83279 ),
	hersheys : ( #1e130c, #9a8478 ),
	talking-to-mice-elf : ( #948e99, #2e1437 ),
	purple-bliss : ( #360033, #0b8793 ),
	predawn : ( #ffa17f, #00223e ),
	endless-river : ( #43cea2, #185a9d ),
	pastel-orange-at-the-sun : ( #ffb347, #ffcc33 ),
	twitch : ( #6441a5, #2a0845 ),
	instagram : ( #517fa4, #243949 ),
	flickr : ( #ff0084, #33001b ),
	vine : ( #00bf8f, #001510 ),
	turquoise-flow : ( #136a8a, #267871 ),
	portrait : ( #8e9eab, #eef2f3 ),
	virgin-america : ( #7b4397, #dc2430 ),
	koko-caramel : ( #d1913c, #ffd194 ),
	fresh-turboscent : ( #f1f2b5, #135058 ),
	green-to-dark : ( #6a9113, #141517 ),
	ukraine : ( #004ff9, #fff94c ),
	curiosity-blue : ( #525252, #3d72b4 ),
	dark-knight : ( #ba8b02, #181818 ),
	piglet : ( #ee9ca7, #ffdde1 ),
	lizard : ( #304352, #d7d2cc ),
	sage-persuasion : ( #ccccb2, #757519 ),
	between-night-and-day : ( #2c3e50, #3498db ),
	timber : ( #fc00ff, #00dbde ),
	passion : ( #e53935, #e35d5b ),
	clear-sky : ( #005c97, #363795 ),
	master-card : ( #f46b45, #eea849 ),
	back-to-earth : ( #00c9ff, #92fe9d ),
	deep-purple : ( #673ab7, #512da8 ),
	little-leaf : ( #76b852, #8dc26f ),
	netflix : ( #8e0e00, #1f1c18 ),
	light-orange : ( #ffb75e, #ed8f03 ),
	green-and-blue : ( #c2e59c, #64b3f4 ),
	poncho : ( #403a3e, #be5869 ),
	back-to-the-future : ( #c02425, #f0cb35 ),
	blush : ( #b24592, #f15f79 ),
	inbox : ( #457fca, #5691c8 ),
	purplin : ( #6a3093, #a044ff ),
	pale-wood : ( #eacda3, #d6ae7b ),
	haikus : ( #fd746c, #ff9068 ),
	pizelex : ( #114357, #f29492 ),
	joomla : ( #1e3c72, #2a5298 ),
	christmas : ( #2f7336, #aa3a38 ),
	minnesota-vikings : ( #5614b0, #dbd65c ),
	miami-dolphins : ( #4da0b0, #d39d38 ),
	forest : ( #5a3f37, #2c7744 ),
	nighthawk : ( #2980b9, #2c3e50 ),
	superman : ( #0099f7, #f11712 ),
	suzy : ( #834d9b, #d04ed6 ),
	dark-skies : ( #4b79a1, #283e51 ),
	deep-space : ( #000000, #434343 ),
	decent : ( #4ca1af, #c4e0e5 ),
	colors-of-sky : ( #e0eafc, #cfdef3 ),
	purple-white : ( #ba5370, #f4e2d8 ),
	ali : ( #ff4b1f, #1fddff ),
	alihossein : ( #f7ff00, #db36a4 ),
	shahabi : ( #a80077, #66ff00 ),
	red-ocean : ( #1d4350, #a43931 ),
	tranquil : ( #eecda3, #ef629f ),
	transfile : ( #16bffd, #cb3066 ),
	sylvia : ( #ff4b1f, #ff9068 ),
);
@mixin uiGradients( $color1, $color2, $direction: left ) {
	background: $color1 !important;
	background: linear-gradient( to #{$direction}, $color1, $color2 ) !important;
}
@each $name, $color in $uiGradients_list {
	$color01: nth( $color, 1 );
	$color02: nth( $color, 2 );
	.bg-#{$name} {
		@include uiGradients( $color01, $color02, left );
		&.-right {
			@include uiGradients( $color01, $color02, right );
		}
		&.-top {
			@include uiGradients( $color01, $color02, top );
		}
		&.-bottom {
			@include uiGradients( $color01, $color02, bottom );
		}
	}
}