.page-id-17 {
	.article-body {
		h2 {
			margin-top: 2.5rem;
			padding-top: 2.5rem;
			border-top: 1px solid #DDD;
			&:nth-of-type(1) {
				margin-top: 0;
				padding-top: 0;
				border: none;
			}
		}
	}
}