//========================  Top Bar ========================================================================//
#topbar {
	background-color: #FFF;
	color: #000;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 500;
	width: 100%;
	height: 60px;
	transition: all .3s ease-in-out !important;
	transform: translateY(0);
	&.is-unpinned {
		transform: translateY(-100%);
	}
	.menu {
		background-color: #FFF;
		@include breakpoint(medium down) {
			background-color: transparent;
		}
		li {
			height: 60px;
		}
		a {
			color: currentColor;
			text-decoration: none;
			&:hover {
				color: $primary-color;
			}
		}
		.active > a {
			color: $primary-color;
		}
	}
	.submenu {
		background-color: #FFF;
		z-index: 10;
	}
}
//========================  Menu Button ========================================================================//
#btn-menu {
	display: inline-block;
	margin: 6px auto;
	width: 50px;
	height: 47px;
	button {
		display: table;
		text-align: center;
		padding: 1rem;
		border: 1px solid #000;
		border-radius: 3px;
		color: #000;
		width: 50px;
		height: 47px;
	}
	.menu-icon {
		display: table-cell;
		vertical-align: middle;
		&::after {
			background-color: #000;
			box-shadow: 0 7px 0 #000, 0 14px 0 #000;
		}
	}
	svg {
		width: 100%;
		height: 100%;
		opacity: 0;
		visibility: hidden;
	}
}
//========================  Responsive Menu ========================================================================//
#responsive-menu {
	float: left;
}
@include breakpoint( medium down ) {
	#responsive-menu {
		z-index: 500;
		position: absolute;
		width: 100%;
		top: 60px;
		li {
			display: block;
			opacity: 0;
			visibility: hidden;
			transform: {
				property: opacity, visibility;
				duration: .5s, .5s;
				delay: .05s, .05s;
			}
			& > a:hover,
			&.active > a,
			&.active > a:hover {
				color: $primary-color !important;
				font-weight: bold !important;
			}
		}
	}
}
#overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba( #FFF, .95 );
	transition: opacity .5s, visibility 0s .5s;
	visibility: hidden;
	opacity: 0;
}
.active-overlay {
	overflow: hidden;
	#btn-menu {
		svg {
			visibility: visible;
			opacity: 1;
		}
	}
	#responsive-menu li {
		opacity: 1;
		visibility: visible;
		transform: {
			property: opacity, visibility;
			duration: .05s, .05s;
			delay: .05s, .05s;
		}
	}
	#overlay {
		visibility: visible;
		opacity: 1;
		transition: opacity .5s;
		z-index: 400;
	}
}
//========================  Topbar CTA ========================================================================//
.topbar-cta {
	@extend .button-group;
	margin: 0;
	line-height: 60px;
	float: right;
	.button {
		font-size: 1rem !important;
		text-decoration: none;
	}
	@include breakpoint( 370px down ) {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
}
//========================  Page Header ========================================================================//
#pageheader {
	position: absolute;
	z-index: 10;
	top: 50px;
	left: 0;
	width: 100%;
	.kkc-logo {
		padding: 30px 0 0;
		padding-left: 5px;
		display: inline-block;
	}
}
.home .kkc-logo {
	padding-top: 40px;
}
//========================  Header Hero ========================================================================//
#hero {
	position: relative;
	z-index: 1;
	width: 100%;
	height: 100%;
	margin-top: 60px;
	padding-top: 60px;
	max-height: 300px;
	background: {
		repeat: no-repeat;
		position: center center;
		size: cover;
	}
	color: #FFF;
	.texture-overlay {
		z-index: 5;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url(#{$imgfolder}grid.png);
	}
	svg {
		fill: #FFF;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		z-index: 10;
		opacity: .8;
		@include breakpoint(medium down) {
			max-width: 90%;
		}
		@include breakpoint(large) {
			max-width: 50%;
		}
	}
}
.home #hero,
.page-template-page-apply #hero {
	max-height: 500px;
}