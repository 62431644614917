$fastDuration: 0.1s;
$mediumDuration: 0.3s;
$slowDuration: 0.5s;
//========================  Animations ========================================================================//
@keyframes box-shining {
	0% {
		left: -300%;
	}
	20% {
		left: 300%;
	}
	100% {
		left: 300%;
	}
}

// テキストインデント
@mixin textindent {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

// 縦横中央揃え
@mixin center {
	display: table;
	text-align: center !important;
	& > * {
		display: table-cell;
		vertical-align: middle;
	}
}

// nisshin.dtdsh Frame Wroks
// Color
$colorname:
	'white',
	'ghost',
	'snow',
	'vapor',
	'white-smoke',
	'silver',
	'smoke',
	'gainsboro',
	'iron',
	'base',
	'aluminum',
	'jumbo',
	'monsoon',
	'steel',
	'charcoal',
	'tuatara',
	'oil',
	'jet',
	'black',
	'primary',
	'secondary',
	'success',
	'warning',
	'alert',
	'light-gray',
	'medium-gray',
	'dark-gray',
	'facebook',
	'hatena',
	'twitter',
	'google',
	'googleplus',
	'pocket',
	'codepen',
	'github',
	'tambler',
	'insatagram',
	'linkedin',
	'vimeo',
	'vine',
	'spotify',
	'rss',
	'blogger',
	'mixi',
	'youtube',
	'pinterest',
	'flickr',
	'foursquare',
	'bitbacket',
	'peypal',
	'wordpress',
	'skype',
	'feedly',
	'evernote',
	'sourcetree',
	'windows',
	'sass',
	'gulp',
	'git',
	'html5',
	'firefox',
	'grunt',
	'android',
	'red',
	'pink',
	'purple',
	'deep-purple',
	'indigo',
	'blue',
	'light-blue',
	'cyan',
	'teal',
	'green',
	'light-green',
	'lime',
	'm-yellow',
	'amber',
	'orange',
	'deep-orange',
	'brown',
	'blue-gray',
	'web',
	'print',
;
$colorlist:
	$white,
	$ghost,
	$snow,
	$vapor,
	$white-smoke,
	$silver,
	$smoke,
	$gainsboro,
	$iron,
	$base,
	$aluminum,
	$jumbo,
	$monsoon,
	$steel,
	$charcoal,
	$tuatara,
	$oil,
	$jet,
	$black,
	$primary-color,
	$secondary-color,
	$success-color,
	$warning-color,
	$alert-color,
	$light-gray,
	$medium-gray,
	$dark-gray,
	$facebook,
	$hatena,
	$twitter,
	$google,
	$googleplus,
	$pocket,
	$codepen,
	$github,
	$tambler,
	$insatagram,
	$linkedin,
	$vimeo,
	$vine,
	$spotify,
	$rss,
	$blogger,
	$mixi,
	$youtube,
	$pinterest,
	$flickr,
	$foursquare,
	$bitbacket,
	$peypal,
	$wordpress,
	$skype,
	$feedly,
	$evernote,
	$sourcetree,
	$windows,
	$sass,
	$gulp,
	$git,
	$html5,
	$firefox,
	$grunt,
	$android,
	$red,
	$pink,
	$purple,
	$deep-purple,
	$indigo,
	$blue,
	$light-blue,
	$cyan,
	$teal,
	$green,
	$light-green,
	$lime,
	$m-yellow,
	$amber,
	$orange,
	$deep-orange,
	$brown,
	$blue-gray,
	$web,
	$print,
;
@each $color in $colorname {
	$index_key: index( $colorname, $color);
	.color-#{$color} {
		color: nth($colorlist, $index_key) !important;
	}
	.hover-#{$color} {
		&:hover { color: nth($colorlist, $index_key) !important }
	}
	.bg-#{$color} {
		background-color: nth($colorlist, $index_key) !important;
	}
	.hover-bg-#{$color} {
		&:hover { background-color: nth($colorlist, $index_key) !important }
	}
	.border-#{$color} {
		border-color: nth($colorlist, $index_key) !important;
	}
}
.bg-line {
	font-weight: 700;
	font-weight: 500;
	padding: .2rem;
	background: linear-gradient( transparent 40%, yellow 40% );
}
.bg-grafpaper {
	@extend .p2;
	@extend .mb1;
	background: url(#{$imgfolder}logos-bg.png) repeat;
	border-radius: $global-radius;
	li {
		@extend .mb3;
		p:first-child {
			font: {
				size: 120%;
				weight: bold;
			}
			@extend .border-oil;
			@extend .bb1;
		}
	}
}

@for $i from 0 through 10 {
	// Borders
	.b#{$i} {
		border: $i * 1px solid !important;
	}
	.bt#{$i} {
		border-top: $i * 1px solid;
	}
	.br#{$i} {
		border-right: $i * 1px solid;
	}
	.bb#{$i} {
		border-bottom: $i * 1px solid;
	}
	.bl#{$i} {
		border-left: $i * 1px solid;
	}
	// Whitespace
	.m#{$i}-auto {
		margin: $i * 1rem auto !important;
	}
	.m#{$i} {
		margin: $i *1rem !important
	}
	.mt#{$i} {
		margin-top: $i *1rem !important
	}
	.mr#{$i} {
		margin-right: $i *1rem !important
	}
	.mb#{$i} {
		margin-bottom: $i *1rem !important
	}
	.ml#{$i} {
		margin-left: $i *1rem !important
	}
	.p#{$i} {
		padding: $i *1rem !important
	}
	.pt#{$i} {
		padding-top: $i *1rem !important
	}
	.pr#{$i} {
		padding-right: $i *1rem !important
	}
	.pb#{$i} {
		padding-bottom: $i *1rem !important
	}
	.pl#{$i} {
		padding-left: $i *1rem !important
	}
	.fs-#{$i} {
		font-size: $i * 1rem;
	}
}
.fs-1-half {
	font-size: 1.5rem;
}
// Depth
@for $i from 0 through 10 {
	.depth-#{$i} {
		z-index: $i * 100 !important
	}
}

// Box shadow
.b-shadow-0 {
	box-shadow: none !important
}

.b-shadow-1 {
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, .12);
	transition: box-shadow $mediumDuration;
}

.b-shadow-1-half {
	box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
	transition: box-shadow $mediumDuration;
}

.b-shadow-2 {
	box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
	transition: box-shadow $mediumDuration;
}

.b-shadow-3 {
	box-shadow: 0 12px 15px 0 rgba(0, 0, 0, .24), 0 17px 50px 0 rgba(0, 0, 0, .19);
	transition: box-shadow $mediumDuration;
}

.b-shadow-4 {
	box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .22), 0 25px 55px 0 rgba(0, 0, 0, .21);
	transition: box-shadow $mediumDuration;
}

.b-shadow-5 {
	box-shadow: 0 27px 24px 0 rgba(0, 0, 0, .2), 0 40px 77px 0 rgba(0, 0, 0, .22);
	transition: box-shadow $mediumDuration;
}

// Text shadow
.t-shadow-0 {
	text-shadow: none !important
}

.t-shadow-1 {
	text-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, .12);
}

.t-shadow-1-half {
	text-shadow: 0 5px 11px rgba(0, 0, 0, .18), 0 4px 15px rgba(0, 0, 0, .15);
}

.t-shadow-2 {
	text-shadow: 0 8px 17px rgba(0, 0, 0, .2), 0 6px 20px rgba(0, 0, 0, .19);
}

.t-shadow-3 {
	text-shadow: 0 12px 15px rgba(0, 0, 0, .24), 0 17px 50px rgba(0, 0, 0, .19);
}

.t-shadow-4 {
	text-shadow: 0 16px 28px rgba(0, 0, 0, .22), 0 25px 55px rgba(0, 0, 0, .21);
}

.t-shadow-5 {
	text-shadow: 0 27px 24px rgba(0, 0, 0, .2), 0 40px 77px rgba(0, 0, 0, .22);
}