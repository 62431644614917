// Global Settings
$noto-font: "メイリオ", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$imgfolder: '../img/';
$font: '../font/';
// Frame Works Import
@import 'color-settings';

// Fonts
@import 'fonts/font-awesome';
@import 'fonts/font-include';

@import 'foundation-settings';
@import 'foundation';

// Foundation Include
@include foundation-global-styles;
// @include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// Frameworks
@import 'mixin';
@import 'framework/uiGradients';
@import 'animate';
@import 'framework/waves';



// Layout
@import 'layout/all';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/sidebar';
@import 'layout/content';
@import 'layout/archive';
@import 'layout/princing-table';

// Project
@import 'project/member';
@import 'project/aboutus';
@import 'project/apply';

// Front Page
@import 'frontpage';

// WordPress
@import 'wordpress';
