*, *::before, *::after { box-sizing: border-box; }
// Font
.font-noto {
	font-family: $noto-font !important;
}
.font-bold {
	font-weight: 500 !important;
}
img,
svg {
	width: auto;
	max-width: 100%;
	height: auto;
	fill: currentColor;
}
svg:not(:root) {
	overflow: hidden;
}
.menu a,
.button {
	text-decoration: none;
}
// 外部リンク
.l-link_external {
	display: inline-block;
	position: relative;
	padding-right: 2.2em;
	transition: $mediumDuration;
	font-family: $noto-font;
	&::after {
		position: absolute;
		right: 0.5em;
		padding: 0 1px;
		font-family: FontAwesome;
		transform: translateZ(0);
		content: '\f08e';
	}
	&:hover {
		text-decoration: none;
	}
}
// PDFリンク
a[href$=".pdf"] {
	display: inline-block;
	position: relative;
	padding-left: 2.2em;
	transition: $mediumDuration;
	font-family: $noto-font;
	&::after {
		position: absolute;
		left: 0.5em;
		padding: 0 1px;
		font-family: FontAwesome;
		transform: translateZ(0);
		content: '\f1c1';
	}
	&:hover {
		text-decoration: none;
	}
}