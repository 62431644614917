// FOUNDATION 5.5
$white          : #FFFFFF;
$ghost          : #FAFAFA;
$snow           : #F9F9F9;
$vapor          : #F6F6F6;
$white-smoke    : #F5F5F5;
$silver         : #EFEFEF;
$smoke          : #EEEEEE;
$gainsboro      : #DDDDDD;
$iron           : #CCCCCC;
$base           : #AAAAAA;
$aluminum       : #999999;
$jumbo          : #888888;
$monsoon        : #777777;
$steel          : #666666;
$charcoal       : #555555;
$tuatara        : #444444;
$oil            : #333333;
$jet            : #222222;
$black          : #000000;

// Foundation 6 & nisshin.dtdsh main color
$primary-color: #010066;
$accent-color: #191970;
$secondary-color: #777777;
$success-color: #3adb76;
$warning-color: #ffae00;
$alert-color: #ec5840;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;



// SOCIAL COLOR
$facebook       : #3B5998;
$hatena         : #2C6EBD;
$twitter        : #55ACEE;
$google         : #CC3732;
$googleplus     : #dd4b39;
$pocket         : #DC3D50;
$line           : #00C300;
$codepen        : #000000;
$github         : #171515;
$tambler        : #44546B;
$insatagram     : #3F729B;
$linkedin       : #0077B5;
$vimeo          : #1AB7EA;
$vine           : #00B489;
$spotify        : #84BD00;
$rss            : #e0812a;
$blogger        : #F57D00;
$mixi           : #E0C074;
$youtube        : #CD201F;
$pinterest      : #CC2127;
$flickr         : #ff0084;
$foursquare     : #0072b1;
$bitbacket      : #003366;
$peypal         : #336699;
$wordpress      : #21759b;
$skype          : #00AFF0;
$feedly         : #33b051;
$evernote       : #6FB536;
$sourcetree     : #1F5081;
$windows        : #00bcf2;
$sass           : #CF649A;
$gulp           : #E94C4F;
$git            : #F03C2E;
$html5          : #F14A29;
$firefox        : #E66000;
$grunt          : #fba919;
$android        : #A4C639;

// マテリアルカラー
$red            : #F44336;
$pink           : #FF4081;
$purple         : #9C27B0;
$deep-purple    : #7C4DFF;
$indigo         : #3F51B5;
$blue           : #448AFF;
$light-blue     : #03A9F4;
$cyan           : #00BCD4;
$teal           : #009688;
$green          : #4CAF50;
$light-green    : #8BC34A;
$lime           : #CDDC39;
$m-yellow       : #FFEB3B;
$amber          : #FFC107;
$orange         : #FF9800;
$deep-orange    : #FF5722;
$brown          : #795548;
$blue-gray      : #607D8B;
$web            : #009688;
$print          : #00BCD4;