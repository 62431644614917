//========================  Global  ========================================================================//
.home section {
	padding: {
		top: 40px;
		bottom: 40px;
	}
}
//========================  Next Event Infomation CTA ========================================================================//
#next-event {
	@extend .bg-dark-skies;
	@extend .-right;
	color: #FFF;
	.where-desc {
		text-align: center;
	}
	.where-info {
		@extend .where-desc;
		font-size: 1.375rem;
	}
}
//========================  Countdown Timer ========================================================================//
#countdown-timer {
	padding: {
		top: 40px;
		bottom: 40px;
	}
	.data-info > .label {
		margin-right: .8rem;
	}
	.timervalue {
		@extend .no-bullet;
		color: #FFF;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		text-align: center;
	}
	li {
		font-size: 40px;
		display: inline-block;
		width: 24%;
		border-right: 1px solid rgba( #FFF, .4 );
		&:last-child {
			border-right: none;
		}
	}
	.timer-name {
		display: block;
		font-size: 11px;
		color: #FFF;
		opacity: .4;
	}
	.timer-number {
		display: block;
		line-height: 40px;
		font-size: 40px;
	}
	.cdt-link {
		padding: 30px 0 0;
		margin: 0 auto;
		text-align: center;
	}
}
//========================  About KKC  ========================================================================//
#about-kkc {
	h2 > span {
		display: block;
		font-size: 1rem;
		margin: .5rem auto;
	}
	.item-performance {
		padding: {
			top: 40px;
			bottom: 40px;
		}
		text-align: center;
		> img {
			width: 210px;
			height: 210px;
			border-radius: 210px;
			background-color: #84dbff;
		}
		h3 {
			margin: 2rem auto 0;
			font-size: 1.5rem;
			span {
				font: {
					size: 3rem;
					weight: bold;
				}
				color: $blue;
			}
		}
	}
	div.column {
		padding: {
			top: 40px;
			bottom: 40px;
		}
	}
	.text-center > iframe {
		display: inline-block;
	}
}
//========================  About Event  ========================================================================//
#about-event {
	background-color: $snow;
	div.column {
		padding: {
			top: 40px;
			bottom: 40px;
		}
	}
}
//========================  Hello  ========================================================================//
#hello {
	background-color: $snow;
	img {
		border: $global-radius solid #FFF;
		border-radius: $global-radius;
		@extend .b-shadow-1;
	}
	div.column {
		padding: {
			top: 40px;
			bottom: 40px;
		}
	}
	h3 {
		margin-bottom: 1.5rem;
	}
}
//========================  fb-gallary  ========================================================================//
#fb-gallary {
	padding: 0;
	width: 100%;
	.column {
		padding: 0;
	}
	figure {
		position: relative;
		overflow: hidden;
		margin: 0;
		padding: 0;
		img {
			width: 100%;
			max-width: none;
			transition: all .3s ease-in-out;
		}
		&:hover {
			img {
				transform: scale( 1.1 );
			}
			figcaption {
				opacity: 1;
			}
			.caption-content {
				transform: translate( 0, 0 );
			}
		}
	}
	figcaption {
		position: absolute;
		top: 0;
		left: 0;
		padding: 25% 0;
		width: 100%;
		height: 100%;
		background-color: rgba( 63, 97, 132, .85 );
		text-align: center;
		font-size: 15px;
		opacity: 0;
		transition: all .3s ease-in-out;
	}
	.caption-content {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -40px;
		margin-left: -100px;
		width: 200px;
		transform: translate( 0, 15px );
		transition: all .3s ease-in-out;
		a {
			color: #FFF;
			text-shadow: 1px 1px 1px rgba( #000, .004 );
			-webkit-font-smoothing: anitialiased;
			text-decoration: none;
		}
		
		p {
			margin: 1rem 0 0;
			font-size: 14px;
			line-height: 25px;
			font-weight: 400;
		}
	}
}
//========================  Go To Facebook  ========================================================================//
#go-fb {
	padding: 120px 0;
	background-color: #3f6184;
	h2 {
		margin: 0 0 15px;
		color: #FFF;
		font-weight: 400;
		font-size: 40px;
	}
	.fb-btn {
		text-decoration: none;
		margin-top: 25px;
		display: inline-block;
		font-size: 17px;
		letter-spacing: .03em;
		text-transform: uppercase;
		color: #FFF;
		position: relative;
		&::before {
			content: '';
			display: inline-block;
			height: 40px;
			position: absolute;
			bottom: -5px;
			left: 30px;
			right: 30px;
			z-index: -1;
			border-radius: 30em;
			filter: blur( 20px ) brightness( .95 );
			transform-style: preserve-3d;
			transition: all .3s ease-out;
		}
		&:focus,
		&:active {
			color: #FFF;
		}
		&:hover {
			color: #FFF;
			span {
				filter: brightness( 1.05 ) contrast( 1.05 );
				transform: scale( .95 );
			}
			&::before {
				bottom: 0;
				filter: blur( 10px ) brightness( .95 );
			}
		}
		span,
		&::before {
			background: #44EA76;
			background: linear-gradient(to right,  #44EA76 0%, #39FAD7 80%,#39FAD7 100%);
		}
	}
	.fa {
		margin: {
			top: -3px;
			right: 20px;
		}
		font-size: 1.265em;
		vertical-align: middle;
	}
	span {
		display: inline-block;
		padding: 18px 60px;
		border-radius: 50em;
		position: relative;
		z-index: 2;
		font-weight: bold;
		will-change: transform, filter;
		transform-style: preserve-3d;
		transition: all .3s ease-out;
	}
}
//========================  News Event  ========================================================================//
#news-event {
	background-color: #FFF;
	h2 {
		margin-bottom: 40px;
	}
}